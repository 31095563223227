import {
  COOKIE_KEYS,
  COOKIE_POSTFIX,
  COOKIE_PREFIX,
} from "~~/constants/cookieStorage"
import { defineStore, storeToRefs } from "pinia"
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
  setSessionStorage,
} from "@/utils/webStorage"

import Author from "~~/models/layout/media/Author"
import CookieStorage from "~~/services/CookieStorage"
import Oidc from "oidc-client"
import User from "~~/models/auth/User"
import { getDomainFromSubdomain } from "~~/utils/getDomainFromSubdomain"
import { useGlobalBuilder } from "./global-builder"
import { usePageBuilder } from "~/stores/page-builder"
import { useUrlBuilder } from "~/stores/url-builder"

export const useAuthBuilder = defineStore({
  id: "auth-builder",
  state: () => {
    return {
      isLoading: false,
      user: null,
      userManager: null,
      userToken: null,
      goToRegister: false,
      isLogedInSSR: false,
      userSubId: null,
    }
  },
  actions: {
    async actionEdgeRequestWithToken(token) {
      const config = useRuntimeConfig()
      const baseUrl = config.public.baseUrl

      try {
        const response = await $fetch(`${baseUrl}/token`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return response
      } catch (error) {
        console.error(error)
      }
    },
    setUserManager() {
      const pageBuilderStore = usePageBuilder()
      // const urlBuilderStore = useUrlBuilder();

      if (!pageBuilderStore.providersData) {
        throw "Login is not implemented or auth-provider issues"
      }

      this.checkUserInfo(pageBuilderStore.providersData)

      // const route = useRoute()
      // authority: "https://account.gjirafa.tech",
      // client_id: "febf14ba-bb71-4036-a063-beddbf616e10",
      // redirect_uri:redirectUri,
      // silent_redirect_uri: `http://localhost:3000/callback`,
      // response_type: "code",
      // scope: "vpapps_api openid profile address email offline_access",
      // post_logout_redirect_uri: 'http://localhost:3000',
      const authority = pageBuilderStore.providersData.issuer
      const redirectUri = `${location.origin}/callback`
      const postLogoutRedirectUri = location.origin
      const clientId = pageBuilderStore.providersData.clientId
      const responseType = "code"
      const scope = "vpapps_api openid profile address email offline_access"

      const settings = {
        authority: authority,
        client_id: clientId,
        redirect_uri: redirectUri,
        silent_redirect_uri: redirectUri,
        response_type: responseType,
        scope: scope,
        post_logout_redirect_uri: postLogoutRedirectUri,
        userStore: new Oidc.WebStorageStateStore({
          store: window.localStorage,
        }),
        monitorSession: false,
      }

      //store: window.localStorage

      const { UserManager } = Oidc
      this.userManager = new UserManager(settings)

      Oidc.Log.logger = console
      Oidc.Log.level = Oidc.Log.INFO

      this.userManager.events.addAccessTokenExpiring(async () => {
        console.log("AccessToken expiring：")
        const res = await this.userManager.signinSilent()

        console.log("Event signinSilent AFTER expiring...", res)

        await this.actionEdgeRequestWithToken(res["access_token"])
        this.setTokenOnState(res["access_token"])
        this.setTokenOnCookie(res["access_token"])
      })

      this.userManager.events.addAccessTokenExpired(async () => {
        try {
          this.unsetUserOnCookie()
          console.log("AccessToken expired：")
          // this.renewToken()

          console.log("AccessToken signinSilent.....after expired")
          const res = await this.userManager.signinSilent()

          await this.actionEdgeRequestWithToken(res["access_token"])
          this.setTokenOnState(res["access_token"])
          this.setTokenOnCookie(res["access_token"])
        } catch (e) {
          console.error(e)
          this.callLogOut()
          //   logoutUser('auth-login')
        }
      })

      this.userManager.events.addSilentRenewError(() => {
        console.log("events.addSilentRenewError(")
      })

      this.userManager.events.addUserLoaded((user) => {
        console.log("events.addUserLoaded(", user)
        this.setUserOnCookie(user)
      })

      this.userManager.events.addUserUnloaded(() => {
        this.unsetUserOnCookie()
      })

      this.userManager.events.addUserSignedOut(() => {
        this.unsetUserOnCookie()
      })
    },
    setTokenOnCookie(data) {
      const urlBuilderStore = useUrlBuilder()
      const { xVpAppsDomain } = storeToRefs(urlBuilderStore)
      const domain = getDomainFromSubdomain(xVpAppsDomain.value)

      const mAccessToken = useCookie("mAccessToken", {
        maxAge: 60 * 60 * 24 * 3,
        domain: domain,
      })
      mAccessToken.value = data
    },
    setTokenOnState(data) {
      // console.log('Setting token on state .....................from: auth-builder.js with value: ', data)

      const urlBuilderStore = useUrlBuilder()
      urlBuilderStore.actionSetToken(data)
    },
    callLogin() {
      const lastUrl = `${location.pathname}${location.search}`

      setSessionStorage("urlBeforeLoginRedirect", lastUrl)
      const router = useRouter()
      router.push("/auth/porta")

      return
      const globalBuilderStore = useGlobalBuilder()

      // globalBuilderStore.SET_IS_REDIRECTING_TO_LOGIN(true)

      if (!this.userManager) {
        throw "userManager is not set"
      }

      const route = useRoute()
      // const lastUrl = `${location.pathname}${location.search}`

      setSessionStorage("urlBeforeLoginRedirect", lastUrl)


    },
    callLoginForTonos() {
      // this.userManager.clearStaleState()
      const lastUrl = `${location.pathname}${location.search}`
      this.goToRegister = true
      const scrollPosition = window.scrollY
      localStorage.setItem("scrollPositionOfTonosOffer", scrollPosition)
      setSessionStorage("urlBeforeLoginRedirect", lastUrl)
      const router = useRouter()
      router.push("/auth/porta")

      const globalBuilderStore = useGlobalBuilder()

      // globalBuilderStore.SET_IS_REDIRECTING_TO_LOGIN(true)

      if (!this.userManager) {
        throw "userManager is not set"
      }

      const route = useRoute()
      // const lastUrl = `${location.pathname}${location.search}`

      // setSessionStorage("urlBeforeLoginRedirect", lastUrl)

      // this.userManager.signinRedirect()
    },
    callLoginAfterRedirect() {
      // const lastUrl = `${location.pathname}${location.search}`

      // setSessionStorage("urlBeforeLoginRedirect", lastUrl)
      const globalBuilderStore = useGlobalBuilder()
      // this.userManager.clearStaleState()
      // globalBuilderStore.SET_IS_REDIRECTING_TO_LOGIN(true)

      if (!this.userManager) {
        throw "userManager is not set"
      }

      const route = useRoute()

      if (this.goToRegister) {
        const signinOptions = {
          extraQueryParams: {
            action: "Register",
          },
        }

        this.userManager.signinRedirect(signinOptions)
      } else {
        this.userManager.signinRedirect()
      }
    },
    async callLogOut() {
      if (!this.userManager) {
        throw "userManager is not set"
      }
      // removeOidcKeys()

      localStorage.removeItem("user-logged-out")

      if (typeof __VPAPPS__ !== "undefined") {
        __VPAPPS__.user = {
          id: 1,
          uid: null,
          loginStatus: "anonymous",
          premiumStatus: "free",
          intrenal: "ip",
          token: null,
        }
      }

      removeLocalStorage("tonosAccessToken")
      const cookie = new CookieStorage()
      cookie.removeItem("mAccessToken")

      console.log("callLogOut", this.userManager)
      try {
        // await this.userManager.clearStaleState()
        this.unsetUserOnCookie()

        await this.userManager.signoutRedirect()
        // removeOidcKeys()
      } catch (error) {
        console.error(error)
      }
    },
    async callSigninRedirectCallback() {
      const cookie = new CookieStorage()
      cookie.removeItem("user-logged-out")

      try {
        const res = await this.userManager.signinCallback()

        if (!res) return

        const user = new User()
        user.fromData(res.profile)
        this.user = user

        const userManager = await this.userManager.getUser()

        await this.actionEdgeRequestWithToken(res["access_token"])

        return res
      } catch (error) {
        // await this.userManager.clearStaleState()
        // removeOidcKeys()
        // localStorage.clear();
        navigateTo("/")
        console.error(error)
      }
    },
    async getUser() {
      if (!this.userManager) {
        throw "userManager is not set"
      }

      try {
        const res = await this.userManager.getUser()

        if (!res) return

        // console.log('getUser----- auth-builder.js', res)

        const cookie = new CookieStorage()
        if (cookie.getItem("user-logged-out"))
          return this.userManager.removeUser()

        this.setTokenOnState(res["access_token"])
        this.setTokenOnCookie(res["access_token"])

        const user = new User()
        user.fromData(res.profile)
        this.user = user

        this.userToken = res["access_token"]

        // if (user && user.sid) {
        //   vpanalytics.notifyHandlers("onLogin", user)
        // }
      } catch (error) {
        console.error(error)
        this.callLogOut()
      }
    },
    async renewToken() {
      try {
        // alert(1);
        const res = await this.userManager.signinSilent()
        // this.actionEdgeRequestWithToken()
        console.log("renewToken...", res)
      } catch (error) {
        console.error("renewToken", error)
      }
    },
    async actionRequireLogin() {
      if (!this.user) {
        this.callLogin()

        return true
      }
    },
    checkUserInfo(data) {
      if (getLocalStorage(`oidc.user:${data.issuer}:${data.clientId}`)) return

      const tempUser = {}
      const cookie = new CookieStorage()
      COOKIE_KEYS.forEach((el) => {
        let temp = cookie.getItem(`${COOKIE_PREFIX}${el}${COOKIE_POSTFIX}`)

        if (el === "profile") temp = JSON.parse(temp)
        if (el === "expires_at") temp = Number(temp)
        if (temp) Object.assign(tempUser, { [el]: temp })
      })

      if (Object.keys(tempUser).length)
        setLocalStorage(
          `oidc.user:${data.issuer}:${data.clientId}`,
          JSON.stringify(tempUser)
        )
    },
    setUserOnCookie(user) {
      const cookie = new CookieStorage()

      cookie.removeItem("user-logged-out")

      COOKIE_KEYS.forEach((el) => {
        cookie.setItem(
          `${COOKIE_PREFIX}${el}${COOKIE_POSTFIX}`,
          typeof user[el] === "object" ? JSON.stringify(user[el]) : user[el]
        )
      })
    },
    unsetUserOnCookie() {
      const cookie = new CookieStorage()

      cookie.setItem("user-logged-out", true)

      COOKIE_KEYS.forEach((el) => {
        cookie.removeItem(`${COOKIE_PREFIX}${el}${COOKIE_POSTFIX}`)
      })
    },
  },

  getters: {
    getCurrentUserAsAuthor: (state) => {
      const author = new Author()
      author.fromUserToAuthor(state.user)

      return author
    },
    isUserLoggedIn: (state) => state.isLogedInSSR,
  },
})

function removeOidcKeys() {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key && key.startsWith("oidc")) {
      localStorage.removeItem(key)
      i-- // Adjust the index after removing an item
    }
  }
}
